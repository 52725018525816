import * as React from 'react';
import MuiDrawer from '@mui/material/Drawer';
import Close3 from '../../../assets/icons/close3';

import './drawer.css';

type DrawerProps = {
  readonly children: JSX.Element;
  readonly title: string;
  readonly open: boolean;
  readonly onClose: () => void;
};

export default function Drawer({
  children,
  title,
  open,
  onClose
}: DrawerProps): JSX.Element {
  return (
    <MuiDrawer
      open={open}
      onClose={onClose}
      anchor='right'
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: '900px',
          borderRadius: '16px 0px 0px 16px'
        }
      }}
    >
      <div className='drawer-content'>
        <div className='drawer-header'>
          <h5 className='drawer-title'>{title}</h5>
          <Close3 onClick={onClose} />
        </div>

        <div className='drawer-body'>{children}</div>
      </div>
    </MuiDrawer>
  );
}
