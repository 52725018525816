import {
  AutocompleteRenderInputParams,
  TextField,
  styled
} from '@mui/material';
import React from 'react';

type RenderInputProps = {
  readonly params: AutocompleteRenderInputParams;
  readonly hasCarbonCopy?: boolean;
  readonly isCarbonCopySelected?: boolean;
  readonly onClickCarbonCopy?: () => void;
  readonly isBlindCarbonCopySelected?: boolean;
  readonly onClickBlindCarbonCopy?: () => void;
};

const IconsWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-right: 10px;
`;

const CCIcon = styled('span')`
  width: 20px;
  height: 20px;
  font-weight: ${({ selected }) => (selected ? 'bold' : 'normal')};
`;

function RenderInput({
  hasCarbonCopy = false,
  isCarbonCopySelected = false,
  onClickCarbonCopy,
  isBlindCarbonCopySelected = false,
  onClickBlindCarbonCopy,
  params
}: RenderInputProps): JSX.Element {
  return (
    <TextField
      {...params}
      fullWidth
      size='small'
      sx={{
        '& .MuiOutlinedInput-root': {
          minHeight: 44
        }
      }}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {hasCarbonCopy && (
              <IconsWrapper>
                <CCIcon
                  style={{ cursor: 'pointer' }}
                  onClick={onClickCarbonCopy}
                  selected={isCarbonCopySelected}
                >
                  Cc
                </CCIcon>
                <CCIcon
                  style={{ cursor: 'pointer' }}
                  onClick={onClickBlindCarbonCopy}
                  selected={isBlindCarbonCopySelected}
                >
                  Cco
                </CCIcon>
              </IconsWrapper>
            )}
            {params.InputProps.endAdornment}
          </>
        )
      }}
    />
  );
}

export default RenderInput;
