import React from 'react';
import { styled } from '@mui/material/styles';

type ErrorMessageProps = {
  readonly error?: string | null;
};

const Container = styled('p')`
  font-size: 13px;
  font-weight: 500;
  line-height: 23px;
  font-style: italic;
  color: #dc3545;
`;

function ErrorMessage({ error }: ErrorMessageProps): JSX.Element {
  if (!error) {
    return <></>;
  }

  return <Container>{error}</Container>;
}

export default ErrorMessage;
