import React from 'react';

function Visibility(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='19'
      height='18'
      viewBox='0 0 19 18'
      fill='none'
      {...props}
    >
      <mask
        id='mask0_3920_10179'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='19'
        height='18'
      >
        <rect x='0.25' width='18' height='18' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_3920_10179)'>
        <path
          d='M9.2514 11.6827C10.1012 11.6827 10.823 11.3852 11.4167 10.7903C12.0105 10.1955 12.3074 9.4731 12.3074 8.62328C12.3074 7.77346 12.0099 7.05168 11.4151 6.45793C10.8202 5.86418 10.0978 5.56731 9.24798 5.56731C8.39817 5.56731 7.67639 5.86474 7.08264 6.45962C6.48889 7.05451 6.19201 7.77686 6.19201 8.62669C6.19201 9.4765 6.48945 10.1983 7.08433 10.792C7.67921 11.3858 8.40157 11.6827 9.2514 11.6827ZM9.24969 10.65C8.68719 10.65 8.20906 10.4531 7.81532 10.0594C7.42157 9.66561 7.22469 9.18748 7.22469 8.62498C7.22469 8.06248 7.42157 7.58436 7.81532 7.19061C8.20906 6.79686 8.68719 6.59998 9.24969 6.59998C9.81219 6.59998 10.2903 6.79686 10.6841 7.19061C11.0778 7.58436 11.2747 8.06248 11.2747 8.62498C11.2747 9.18748 11.0778 9.66561 10.6841 10.0594C10.2903 10.4531 9.81219 10.65 9.24969 10.65ZM9.25072 13.875C7.52601 13.875 5.95451 13.3992 4.53624 12.4478C3.11798 11.4964 2.07375 10.2221 1.40356 8.62498C2.07375 7.02787 3.11764 5.75359 4.53523 4.80216C5.9528 3.85072 7.52395 3.375 9.24866 3.375C10.9734 3.375 12.5449 3.85072 13.9631 4.80216C15.3814 5.75359 16.4256 7.02787 17.0958 8.62498C16.4256 10.2221 15.3817 11.4964 13.9642 12.4478C12.5466 13.3992 10.9754 13.875 9.25072 13.875ZM9.24969 12.75C10.6622 12.75 11.9591 12.3781 13.1403 11.6344C14.3216 10.8906 15.2247 9.88748 15.8497 8.62498C15.2247 7.36248 14.3216 6.35936 13.1403 5.61561C11.9591 4.87186 10.6622 4.49998 9.24969 4.49998C7.83719 4.49998 6.54031 4.87186 5.35906 5.61561C4.17781 6.35936 3.27469 7.36248 2.64969 8.62498C3.27469 9.88748 4.17781 10.8906 5.35906 11.6344C6.54031 12.3781 7.83719 12.75 9.24969 12.75Z'
          fill='#484848'
        />
      </g>
    </svg>
  );
}

Visibility.displayName = 'Visibility';

export default Visibility;
