import { Box } from '@mui/material';
import React from 'react';

type RenderOptionsProps = {
  readonly option: {
    email: string;
    name: string;
  };
};

function RenderOptions({
  option,
  ...optionProps
}: RenderOptionsProps): JSX.Element {
  return (
    <Box
      component='div'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        width: '100%',
        gap: '0rem',
        '& > span': { width: '100%' },
        '& > span:first-of-type': { fontSize: '1.2rem' },
        '& > span:last-of-type': { fontSize: '1rem' }
      }}
      {...optionProps}
    >
      <span>{option.email}</span>
      <span>{option.name}</span>
    </Box>
  );
}

export default RenderOptions;
