import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { userSelector } from '../../../redux/selectors';
import { hardGoTo as navigate } from '../../../redux/actions';
import { User } from '../../../redux/prop-types';
import AdminLayout from '../../../components/layouts/admin-layout';
import AutomaticEmails from '../../../components/admin/emails/automatics';
import ManualEmails from '../../../components/admin/emails/manuals';
import RecurringEmails from '../../../components/admin/emails/recurring';

import './emails.css';

const mapStateToProps = createSelector(userSelector, (user: User) => ({
  user
}));

const mapDispatchToProps = {
  navigate
};

function Emails(): JSX.Element {
  const [selectTab, setSelectTab] = useState(0);

  const tabs = [
    {
      id: 0,
      name: 'E-mails automáticos',
      content: <AutomaticEmails />
    },
    {
      id: 1,
      name: 'E-mails manuais',
      content: <ManualEmails />
    },
    {
      id: 2,
      name: 'E-mails recorrentes',
      content: <RecurringEmails />
    }
  ];

  return (
    <AdminLayout title={'DEVstart | Emails'}>
      <div className='admin-dashboard-container'>
        <div className='dashboard-header'>
          <h1>Envio de e-mails</h1>
        </div>

        <nav className='navigation-email-type'>
          {tabs.map((tab, index) => (
            <button
              key={tab.id}
              className={selectTab === index ? 'selected' : ''}
              onClick={() => setSelectTab(index)}
            >
              {tab.name}
            </button>
          ))}
        </nav>

        {tabs[selectTab].content}
      </div>
    </AdminLayout>
  );
}

Emails.displayName = 'Emails';

export default connect(mapStateToProps, mapDispatchToProps)(Emails);
