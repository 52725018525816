/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-misused-promises, @typescript-eslint/no-unsafe-assignment */

import React, { ChangeEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  useForm,
  Controller,
  FieldErrorsImpl,
  SubmitHandler
} from 'react-hook-form';
import Select from '@ui/Select';
import Input from '@ui/Input';
import Button from '@ui/Button';
import { ButtonTypes } from '@ui/Button/button-types';
import MultipleSelectCheckmarks from '@ui/MultiSelect';
import { getClassList, postUploadPublicImage } from '@utils/ajax';
import { UserEmails, getUsersByEmail } from '@utils/api/emails';
import AsyncSelect from '@ui/AsyncSelect';
import { isBefore, isEqual, startOfMinute } from 'date-fns';
import { escapeRegExp } from 'lodash';
import RichTextEditor from '@ui/RichTextEditor';
import { setLoadingManualEmails } from '@redux/actions';
import { ClassOption } from '../../../../messages/form';
import {
  ManualFormData,
  ManualEmail as Email,
  EmailRecipients,
  EmailRecipientsTypeOptions,
  FixedEmails,
  RegionalDepartment,
  TargetGroup,
  TargetGroupOptions
} from '../../types';
import CancelEmailBar from '../cancel--email-bar';
import { EmailStatus } from '../../enum';
import RenderOptions from './render-options';
import RenderInput from './render-input';
import Calendar from './calendar';

type ManualEmailFormProps = {
  readonly currentEmail: Email | null;
  readonly useForm: typeof useForm;
  readonly createManualEmail: (data: Email) => void;
  readonly editManualEmail: (data: Email) => void;
  readonly isLoading: boolean;
  readonly setLoadingManualEmails: (isLoading: boolean) => void;
};

const mapDispatchtoProps = { setLoadingManualEmails };

type Option = {
  value: string;
  option: string;
};

const recipientOptions: Option[] = [
  {
    value: 'fixedEmails',
    option: 'E-mails fixos'
  },
  {
    value: 'targetGroup',
    option: 'Grupo-alvo'
  },
  {
    value: 'regionalDepartment',
    option: 'Departamento regional'
  }
];

const targetGroupOptions = [
  { value: 'all', option: 'Todos' },
  { value: 'class', option: 'Turma' },
  { value: 'score', option: 'Pontuação' },
  { value: 'class-and-score', option: 'Turma + Pontuação' }
];

const regionalDepartmentOptions = [
  { value: 'PI', label: 'Piauí' },
  { value: 'PR', label: 'Paraná' }
];

function ManualEmailForm({
  currentEmail,
  useForm,
  createManualEmail,
  editManualEmail,
  isLoading,
  setLoadingManualEmails
}: ManualEmailFormProps): JSX.Element {
  const [classOptionsList, setClassOptionsList] = useState<ClassOption[]>([]);
  const [calendarModalIsOpen, setCalendarModalIsOpen] = useState(false);
  const [isCarbonCopySelected, setIsCarbonCopySelected] = useState(false);
  const [isBlindCarbonCopySelected, setIsBlindCarbonCopySelected] =
    useState(false);
  const [imageFiles, setImageFiles] = useState<
    { file: File; tempUrl: string; markedForUpload: boolean }[]
  >([]);
  const [messageError, setMessageError] = useState<string>('');

  const {
    setValue,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    register,
    formState: { errors },
    control
  } = useForm<ManualFormData>({
    defaultValues: {
      isActive: true,
      recipients: { blindCarbonCopyEmailsList: [], carbonCopyEmailsList: [] }
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      const result: string[] = await getClassList();
      setClassOptionsList(
        result
          .filter(item => !item.startsWith('nacional_'))
          .map((item: string) => ({ value: item, option: item }))
      );
    };

    void fetchData();

    if (currentEmail) {
      const {
        recipients,
        subject,
        emailContent,
        sendDate,
        isActive = true
      } = currentEmail;
      setValue('recipients.type', recipients.type);

      switch (recipients.type) {
        case 'fixedEmails':
          setValue('recipients.fixedEmailsList', recipients.fixedEmailsList);

          if (recipients.carbonCopyEmailsList) {
            setValue(
              'recipients.carbonCopyEmailsList',
              recipients.carbonCopyEmailsList
            );
            setIsCarbonCopySelected(true);
          }

          if (recipients.blindCarbonCopyEmailsList) {
            setValue(
              'recipients.blindCarbonCopyEmailsList',
              recipients.blindCarbonCopyEmailsList
            );
            setIsBlindCarbonCopySelected(true);
          }

          break;
        case 'targetGroup':
          setValue('recipients.targetGroup', recipients.targetGroup);

          if (
            recipients.targetGroup === 'class' ||
            recipients.targetGroup === 'class-and-score'
          ) {
            setValue('recipients.class', recipients.class);
          }

          if (
            recipients.targetGroup === 'score' ||
            recipients.targetGroup === 'class-and-score'
          ) {
            setValue('recipients.minScore', recipients.minScore);
            setValue('recipients.maxScore', recipients.maxScore);
          }

          break;
        case 'regionalDepartment':
          setValue(
            'recipients.regionalDepartment',
            recipients.regionalDepartment
          );
          break;
      }

      setValue('subject', subject);
      setValue('emailContent', emailContent);
      setValue('sendDate', sendDate);
      setValue('isActive', isActive);
    }
  }, [currentEmail, setValue]);

  const type = watch('recipients.type');
  const targetGroup = watch('recipients.targetGroup');
  const sendDate = watch('sendDate');
  const regionalDepartment = watch('recipients.regionalDepartment');
  const fixedEmailsList = watch('recipients.fixedEmailsList');
  const carbonCopyEmailsList = watch('recipients.carbonCopyEmailsList');
  const blindCarbonCopyEmailsList = watch(
    'recipients.blindCarbonCopyEmailsList'
  );

  const emailContent = watch('emailContent');
  const disabled = !!currentEmail;

  const handleOpenCalendarModal = () => {
    setMessageError('');
    setCalendarModalIsOpen(true);
  };

  const handleCloseCalendarModal = () => {
    setCalendarModalIsOpen(false);
  };

  const handleChooseDate = (date: Date) => {
    setValue('sendDate', date);
    if (date) {
      clearErrors('sendDate');
    }
  };

  const handleImageUpload = (file: File, tempUrl: string) => {
    setImageFiles(prev => [...prev, { file, tempUrl, markedForUpload: true }]);
  };

  const handleEditorChange = (content: string) => {
    setValue('emailContent', content);

    setImageFiles(prevFiles =>
      prevFiles.map(imageFile => {
        const { tempUrl } = imageFile;
        const isImageStillPresent = content.includes(tempUrl);
        return {
          ...imageFile,
          markedForUpload: isImageStillPresent
        };
      })
    );
  };

  const validateDate = (date: Date) => {
    const now = startOfMinute(new Date());
    const isBeforeOrEqual =
      isBefore(startOfMinute(new Date(date)), now) ||
      isEqual(startOfMinute(new Date(date)), now);
    return isBeforeOrEqual;
  };

  const onSubmit: SubmitHandler<ManualFormData> = async (
    data: ManualFormData
  ) => {
    setLoadingManualEmails(true);
    try {
      if (data.sendDate && validateDate(data.sendDate)) {
        setMessageError(
          'Data e hora para programação do e-mail devem ser posteriores ao momento atual.'
        );
        return;
      }
      const imagesToUpload = imageFiles.filter(
        imageFile => imageFile.markedForUpload
      );

      const uploadedImageUrls = await Promise.all(
        imagesToUpload.map(async ({ file }) => {
          const response = await postUploadPublicImage(file);
          return response.data.result.fileUrl;
        })
      );

      let updatedContent = emailContent;
      imagesToUpload.forEach(({ tempUrl }, index) => {
        const realUrl = uploadedImageUrls[index];

        const escapedTempUrl = escapeRegExp(tempUrl);

        updatedContent = updatedContent.replace(
          new RegExp(escapedTempUrl, 'g'),
          realUrl
        );
      });

      if (currentEmail) {
        const emailPayload: Email = {
          ...currentEmail,
          ...data,
          emailContent: updatedContent
        };
        editManualEmail({ id: currentEmail.id, ...emailPayload });
        return;
      }

      const emailPayload: Email = {
        ...data,
        createdAt: new Date(),
        changeLog: [],
        status: EmailStatus.SCHEDULED,
        emailContent: updatedContent,
        isActive: typeof data.isActive === 'boolean' ? data.isActive : true
      };
      createManualEmail(emailPayload);
    } catch (error) {
      console.error('Erro ao enviar e-mail:', error);
    } finally {
      setLoadingManualEmails(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {!!currentEmail && currentEmail?.id && sendDate && (
        <CancelEmailBar
          id={currentEmail?.id}
          date={sendDate}
          status={currentEmail?.status}
        />
      )}

      <Controller
        control={control}
        name='recipients.type'
        rules={{
          required: {
            value: true,
            message: 'Campo obrigatório'
          }
        }}
        render={({ field: { name, value, onChange } }) => (
          <Select
            label='Destinatário'
            placeholder='Selecione'
            defaultValue=''
            options={recipientOptions}
            name={name}
            value={value}
            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
              const value = event.target.value as EmailRecipientsTypeOptions;
              setValue('recipients', { type: value } as EmailRecipients);
              onChange(value);
            }}
            error={
              (errors.recipients as FieldErrorsImpl<EmailRecipients>)?.type
                ?.message
            }
            disabled={disabled}
          />
        )}
      />

      {['fixedEmails'].includes(type) && (
        <>
          <AsyncSelect
            getOptionLabel={(option: UserEmails) => option.email}
            getOptionKey={(option: unknown) => (option as { id: string }).id}
            defaultValue={fixedEmailsList}
            renderOption={(props, option: UserEmails) => (
              <RenderOptions option={option} {...props} />
            )}
            renderInput={params => (
              <RenderInput
                params={params}
                hasCarbonCopy
                isCarbonCopySelected={isCarbonCopySelected}
                onClickCarbonCopy={() => setIsCarbonCopySelected(prev => !prev)}
                isBlindCarbonCopySelected={isBlindCarbonCopySelected}
                onClickBlindCarbonCopy={() =>
                  setIsBlindCarbonCopySelected(prev => !prev)
                }
              />
            )}
            handleSearch={getUsersByEmail}
            label='E-mails'
            {...register('recipients.fixedEmailsList', {
              validate: value => {
                return (
                  (Array.isArray(value) && value.length > 0) ||
                  'Campo obrigatório'
                );
              }
            })}
            onChange={(_e, value: UserEmails[]) =>
              setValue('recipients.fixedEmailsList', value)
            }
            error={
              (errors.recipients as FieldErrorsImpl<FixedEmails>)
                ?.fixedEmailsList?.message
            }
            disabled={disabled}
          />

          {isCarbonCopySelected && (
            <AsyncSelect
              getOptionLabel={(option: UserEmails) => option.email}
              getOptionKey={(option: unknown) => (option as { id: string }).id}
              defaultValue={carbonCopyEmailsList}
              renderOption={(props, option: UserEmails) => (
                <RenderOptions option={option} {...props} />
              )}
              renderInput={params => <RenderInput params={params} />}
              handleSearch={getUsersByEmail}
              label='Cópia de e-mail'
              {...register('recipients.carbonCopyEmailsList')}
              onChange={(_e, value: UserEmails[]) =>
                setValue('recipients.carbonCopyEmailsList', value)
              }
              error={
                (errors.recipients as FieldErrorsImpl<FixedEmails>)
                  ?.carbonCopyEmailsList?.message
              }
              disabled={disabled}
            />
          )}

          {isBlindCarbonCopySelected && (
            <AsyncSelect
              getOptionLabel={(option: UserEmails) => option.email}
              getOptionKey={(option: unknown) => (option as { id: string }).id}
              defaultValue={blindCarbonCopyEmailsList}
              renderOption={(props, option: UserEmails) => (
                <RenderOptions option={option} {...props} />
              )}
              renderInput={params => <RenderInput params={params} />}
              handleSearch={getUsersByEmail}
              label='Cópia de e-mail oculta'
              {...register('recipients.blindCarbonCopyEmailsList')}
              onChange={(_e, value: UserEmails[]) =>
                setValue('recipients.blindCarbonCopyEmailsList', value)
              }
              error={
                (errors.recipients as FieldErrorsImpl<FixedEmails>)
                  ?.blindCarbonCopyEmailsList?.message
              }
              disabled={disabled}
            />
          )}
        </>
      )}

      {['targetGroup'].includes(type) && (
        <Controller
          control={control}
          name='recipients.targetGroup'
          rules={{
            required: {
              value: type === 'targetGroup',
              message: 'Campo obrigatório'
            }
          }}
          render={({ field: { value, onChange } }) => (
            <Select
              label='Grupo-alvo'
              placeholder='Selecione'
              defaultValue=''
              options={targetGroupOptions}
              onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                const value = event.target.value as TargetGroupOptions;
                setValue('recipients', {
                  type: 'targetGroup',
                  targetGroup: value
                });
                onChange(value);
              }}
              value={value}
              error={
                (errors.recipients as FieldErrorsImpl<TargetGroup>)?.targetGroup
                  ?.message
              }
              disabled={disabled}
            />
          )}
        />
      )}

      {type === 'targetGroup' && (
        <>
          {['class', 'class-and-score'].includes(targetGroup) && (
            <Controller
              control={control}
              name='recipients.class'
              rules={{
                required: {
                  value:
                    type === 'targetGroup' &&
                    (targetGroup === 'class' ||
                      targetGroup === 'class-and-score'),
                  message: 'Campo obrigatório'
                }
              }}
              render={({ field }) => (
                <Select
                  label='Turma'
                  placeholder='Selecione'
                  defaultValue=''
                  options={classOptionsList}
                  onChangeSelect={(event: ChangeEvent<HTMLSelectElement>) => {
                    setValue('recipients.class', event.target.value);
                  }}
                  value={field.value}
                  onChange={field.onChange}
                  error={
                    (errors.recipients as FieldErrorsImpl<TargetGroup>)?.class
                      ?.message
                  }
                  disabled={disabled}
                />
              )}
            />
          )}

          {['score', 'class-and-score'].includes(targetGroup) && (
            <div className='form-line'>
              <Controller
                control={control}
                name='recipients.minScore'
                rules={{
                  required: {
                    value:
                      type === 'targetGroup' &&
                      (targetGroup === 'score' ||
                        targetGroup === 'class-and-score'),
                    message: 'Campo obrigatório'
                  }
                }}
                render={({ field }) => (
                  <Input
                    id='email-min-score'
                    label='Pontuação mínima'
                    placeholder='Pontuação mínima'
                    type='number'
                    style={{ flex: 1 }}
                    {...field}
                    error={
                      (errors.recipients as FieldErrorsImpl<TargetGroup>)
                        ?.minScore?.message
                    }
                    disabled={disabled}
                  />
                )}
              />

              <Controller
                control={control}
                name='recipients.maxScore'
                rules={{
                  required: {
                    value:
                      type === 'targetGroup' &&
                      (targetGroup === 'score' ||
                        targetGroup === 'class-and-score'),
                    message: 'Campo obrigatório'
                  }
                }}
                render={({ field }) => (
                  <Input
                    id='email-max-score'
                    label='Pontuação máxima'
                    placeholder='Pontuação máxima'
                    type='number'
                    style={{ flex: 1 }}
                    {...field}
                    error={
                      (errors.recipients as FieldErrorsImpl<TargetGroup>)
                        ?.maxScore?.message
                    }
                    disabled={disabled}
                  />
                )}
              />
            </div>
          )}
        </>
      )}

      {['regionalDepartment'].includes(type) && (
        <Controller
          control={control}
          name='recipients.regionalDepartment'
          rules={{
            required: {
              value: type === 'regionalDepartment',
              message: 'Campo obrigatório'
            },
            min: {
              value: 1,
              message: 'Selecione pelo menos um item'
            }
          }}
          render={({ field }) => (
            <MultipleSelectCheckmarks
              options={regionalDepartmentOptions}
              selectedOptions={regionalDepartment?.map(item => {
                const option = regionalDepartmentOptions.find(
                  option => option.value === item
                );
                return {
                  label: option?.label ?? item,
                  value: item
                };
              })}
              {...field}
              onChange={newValue => {
                if (newValue.length > 0) {
                  clearErrors('recipients.regionalDepartment');
                } else {
                  setError('recipients.regionalDepartment', {
                    type: 'required',
                    message: 'Campo obrigatório'
                  });
                }
                setValue(
                  'recipients.regionalDepartment',
                  newValue.map(item => item.value)
                );
              }}
              error={
                (errors.recipients as FieldErrorsImpl<RegionalDepartment>)
                  ?.regionalDepartment?.message
              }
              disabled={disabled}
            />
          )}
        />
      )}

      <Controller
        name='subject'
        control={control}
        rules={{
          required: {
            value: true,
            message: 'Campo obrigatório'
          }
        }}
        render={({ field }) => (
          <Input
            label='Assunto'
            placeholder='Assunto'
            type='text'
            error={errors.subject?.message}
            disabled={disabled}
            {...field}
          />
        )}
      />

      <Controller
        name='emailContent'
        control={control}
        rules={{
          required: {
            value: true,
            message: 'Campo obrigatório'
          }
        }}
        render={({ field: { value } }) => (
          <>
            <RichTextEditor
              error={errors.emailContent?.message}
              defaultValue={value}
              onChange={handleEditorChange}
              onImageUpload={handleImageUpload}
              disabled={disabled}
            />
            {messageError && (
              <span className='error-message'>{messageError}</span>
            )}
          </>
        )}
      />

      <div className='drawer-footer' style={{ justifyContent: 'flex-end' }}>
        <div>
          <Button
            type='button'
            buttonType={ButtonTypes.Tertiary}
            style={{ height: 34 }}
            onClick={handleOpenCalendarModal}
            disabled={disabled || isLoading}
          >
            Programar envio
          </Button>

          <Button
            buttonType={ButtonTypes.Primary}
            style={{ height: 34 }}
            disabled={disabled || isLoading}
            isLoading={isLoading}
          >
            {sendDate ? 'Agendar' : 'Enviar'}
          </Button>
        </div>
      </div>

      <Calendar
        show={calendarModalIsOpen}
        handleClose={handleCloseCalendarModal}
        value={sendDate}
        onChoose={handleChooseDate}
        removeSendDate={() => {
          setValue('sendDate', null);
          handleCloseCalendarModal();
        }}
      />
    </form>
  );
}

export default connect(null, mapDispatchtoProps)(ManualEmailForm);
