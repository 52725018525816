import Drawer from '@ui/Drawer';
import React, { useEffect, useState } from 'react';
import Table from '@components/Table';
import { ColumnTypes } from '@components/Table/types';
import { getEmailsByRecipient } from '@utils/api/emails';
import { FetchEmailsProps } from '@components/admin/emails/types';
import { EmailRecipients } from '../../types';

type DrawerRecipientsProps = {
  readonly isOpenRecipients: boolean;
  readonly currentRecipients: EmailRecipients | null;
  readonly handleCloseModalRecipients: () => void;
};

type RecipientsPayloadProps = EmailRecipients & {
  pageSize: number;
  pageNumber: number;
};

type EmailsWithCarbonCopyProps = { email: string; cc: string; bcc: string };

const tableColumnsRecipients: ColumnTypes<
  string | EmailsWithCarbonCopyProps
>[] = [
  {
    key: 'recipients',
    title: 'Destinatários',
    render: (_, recipient) => <span>{recipient}</span>
  }
];

const tableColumnsEmailsListRecipients: ColumnTypes<
  string | EmailsWithCarbonCopyProps
>[] = [
  {
    key: 'recipients',
    title: 'Destinatários',
    render: (_, item) => (
      <span>{typeof item === 'string' ? item : item.email}</span>
    )
  },
  {
    key: 'cc',
    title: 'Cc',
    render: (_, item) => (
      <span>{typeof item === 'string' ? item : item.cc}</span>
    )
  },
  {
    key: 'bcc',
    title: 'Cco',
    render: (_, item) => (
      <span>{typeof item === 'string' ? item : item.bcc}</span>
    )
  }
];

function DrawerRecipients({
  isOpenRecipients,
  currentRecipients,
  handleCloseModalRecipients
}: DrawerRecipientsProps) {
  const [recipientEmails, setRecipientEmails] = useState<
    string[] | { email: string; cc: string; bcc: string }[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [listSize, setListSize] = useState(0);

  const fetchRecipientsEmails = (recipients: RecipientsPayloadProps) => {
    setLoading(true);
    getEmailsByRecipient(recipients)
      .then(res => {
        setRecipientEmails(res.emails);
        setListSize(res.listSize);
        setLoading(false);
      })
      .catch((err: Error) => {
        console.log(`Error while fetching emails: ${err.message}`);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (currentRecipients) {
      const currentRecipientsPayload: RecipientsPayloadProps = {
        ...currentRecipients,
        pageNumber: 1,
        pageSize: 5
      };
      fetchRecipientsEmails(currentRecipientsPayload);
    }
  }, [currentRecipients]);

  if (!currentRecipients) return <></>;

  function getPagination(props?: FetchEmailsProps) {
    const pageNumber = props?.pageNumber ?? 1;
    const payload: RecipientsPayloadProps = {
      ...(currentRecipients as EmailRecipients),
      pageNumber,
      pageSize: 5
    };
    fetchRecipientsEmails(payload);
  }

  return (
    <Drawer
      open={isOpenRecipients}
      onClose={handleCloseModalRecipients}
      title='Destinatários'
    >
      <Table
        data={recipientEmails}
        columns={
          currentRecipients.type === 'fixedEmails'
            ? tableColumnsEmailsListRecipients
            : tableColumnsRecipients
        }
        showFooter={true}
        dataCount={listSize}
        fetchMessages={getPagination}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        isLoading={loading}
      />
    </Drawer>
  );
}

export default DrawerRecipients;
