import React from 'react';
import { connect } from 'react-redux';
import { cancelManualEmail } from '@redux/actions';
import { styled } from '@mui/material/styles';
import Calendar3 from '@assets/icons/calendar3';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { ButtonTypes } from '@ui/Button/button-types';
import DeleteModal from '../delete-modal';
import { EmailStatus } from '../../enum';

type CancelEmailBarProps = {
  readonly id: string;
  readonly date: Date;
  readonly status: string;
  readonly cancelManualEmail: ({ id }: { id: string }) => void;
};

const Container = styled('div')`
  border-radius: 8px;
  background: var(--Pallets-Neutral-25, #ebebeb);
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 24px;
  margin-bottom: 2rem;
  gap: 12px;
`;

const Content = styled('span')`
  color: var(--Pallets-Neutral-700, #484848);
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const CancelEmailButton = styled('button')`
  border: none;
  background: transparent;
  color: var(--Pallets-Neutral-800, #333);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-left: auto;
  cursor: pointer;
  transition: 0.1s;

  &:active {
    color: var(--Pallets-Neutral-800, #333);
    background: transparent;
    font-weight: 700;
    border-bottom: 1px solid var(--Pallets-Neutral-800, #333);
  }

  &:focus {
    color: var(--Pallets-Neutral-800, #333);
    background: transparent;
    font-weight: 700;
    border-bottom: 1px solid var(--Pallets-Neutral-800, #333);
  }

  &:hover {
    color: var(--Pallets-Neutral-800, #333);
    background: transparent;
    font-weight: 700;
    border-bottom: 1px solid var(--Pallets-Neutral-800, #333);
  }
`;

const mapDispatchtoProps = {
  cancelManualEmail
};

function CancelEmailBar({
  id,
  date,
  status,
  cancelManualEmail
}: CancelEmailBarProps): JSX.Element | null {
  const [open, setOpen] = React.useState(false);

  const handleOpenConfirmationModal = () => {
    setOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setOpen(false);
  };

  const handleCancelEmail = () => {
    cancelManualEmail({ id });
  };

  if (status === EmailStatus.SENT) {
    return null;
  }

  return (
    <Container>
      <Calendar3 />
      <Content>
        {status === EmailStatus.CANCELLED
          ? 'Envio cancelado'
          : `Envio programado para ${format(
              new Date(date),
              "dd 'de' MMMM, 'de' yyyy, H:mm",
              { locale: ptBR }
            )}`}
      </Content>

      {status !== EmailStatus.CANCELLED && (
        <CancelEmailButton
          type='button'
          buttonType={ButtonTypes.Outline}
          onClick={handleOpenConfirmationModal}
        >
          Cancelar envio
        </CancelEmailButton>
      )}

      <DeleteModal
        show={open}
        onHide={handleCloseConfirmationModal}
        confirmAction={handleCancelEmail}
      />
    </Container>
  );
}

export default connect(null, mapDispatchtoProps)(CancelEmailBar);
