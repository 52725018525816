import React from 'react';

function Calendar3(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <mask
        id='mask0_5458_17538'
        style={{ maskType: 'alpha' }}
        width='24'
        height='24'
        x='0'
        y='0'
        maskUnits='userSpaceOnUse'
      >
        <path fill='#D9D9D9' d='M0 0H24V24H0z'></path>
      </mask>
      <g mask='url(#mask0_5458_17538)'>
        <path
          fill='#484848'
          d='M5 8.806h14v-2.5a.294.294 0 00-.096-.212.294.294 0 00-.212-.096H5.308a.294.294 0 00-.212.096.294.294 0 00-.096.212v2.5zm.308 12.692c-.505 0-.933-.175-1.283-.525A1.745 1.745 0 013.5 19.69V6.306c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h1.384V2.383h1.539v2.115h7.577V2.383h1.5v2.115h1.384c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283v5.463a4.864 4.864 0 00-.735-.255 6.99 6.99 0 00-.765-.152v-1.056H5v9.384c0 .077.032.148.096.212a.293.293 0 00.212.096h6.502c.084.277.186.539.306.786.12.246.257.485.409.714H5.308zm12.884 1c-1.248 0-2.31-.438-3.186-1.313-.876-.876-1.314-1.938-1.314-3.187 0-1.249.438-2.31 1.314-3.186.875-.876 1.938-1.314 3.186-1.314 1.25 0 2.311.438 3.187 1.314.875.875 1.313 1.937 1.313 3.186 0 1.249-.438 2.31-1.313 3.187-.876.875-1.938 1.313-3.187 1.313zm1.666-2.211l.623-.623-1.846-1.847v-2.761h-.885v3.123l2.108 2.108z'
        ></path>
      </g>
    </svg>
  );
}

export default Calendar3;
