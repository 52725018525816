import React, { KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { addHours, format } from 'date-fns';
import Switch from '@ui/Switch';
import Drawer from '@ui/Drawer';
import Input from '@ui/Input';
import {
  automaticEmailsSelector,
  isLoadingAutomaticEmailsSelector
} from '@redux/selectors';
import {
  AutomaticEmail,
  AutomaticEmailsProps,
  ChangeLog,
  ToggleStatusProps
} from '@redux/prop-types';
import {
  fetchAutomaticEmails,
  toggleActiveAutomaticEmail
} from '@redux/actions';
import Table from '../../../Table';
import { ColumnTypes } from '../../../Table/types';
import SwapVertical from '../../../../assets/icons/swap-vertical';
import Visibility from '../../../../assets/icons/visibility';
import { FetchEmailsProps } from '../types';
import { FilterOptionsProps } from '../../../../utils/api';
import Magnifier from '../../../../assets/icons/magnifier';
import EmailDetail from './emailDetail';

interface AutomaticEmailsPageProps {
  readonly automaticEmails: AutomaticEmailsProps;
  readonly isLoadingAutomaticEmails: boolean;
  readonly fetchAutomaticEmails: (filter: FilterOptionsProps) => void;
  readonly toggleActiveAutomaticEmail: (props: ToggleStatusProps) => void;
}

const initialFilterOptions: FilterOptionsProps = {
  pageNumber: 1,
  pageSize: 5,
  search: '',
  sort: 'subject',
  order: 'ASC'
};

const mapStateToProps = createSelector(
  automaticEmailsSelector,
  isLoadingAutomaticEmailsSelector,
  (
    automaticEmails: AutomaticEmailsProps,
    isLoadingAutomaticEmails: boolean
  ) => ({
    automaticEmails,
    isLoadingAutomaticEmails
  })
);

const mapDispatchToProps = {
  fetchAutomaticEmails,
  toggleActiveAutomaticEmail
};

function AutomaticEmails({
  automaticEmails,
  isLoadingAutomaticEmails,
  fetchAutomaticEmails,
  toggleActiveAutomaticEmail
}: AutomaticEmailsPageProps): JSX.Element {
  const [isOpenChangeLog, setIsOpenChangeLog] = useState(false);
  const [isOpenActions, setIsOpenActions] = useState(false);
  const [currentEmail, setCurrentEmail] = useState<AutomaticEmail | null>(null);
  const [emailsList, setEmailsList] = useState<AutomaticEmail[]>([]);
  const [emailsCount, setEmailsCount] = useState<number>(0);
  const [filter, setFilter] =
    useState<FilterOptionsProps>(initialFilterOptions);
  const [selectedOrderColumn, setSelectedOrderColumn] = useState('subject');
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [currentChangeLog, setCurrentChangeLog] = useState<ChangeLog[]>([]);
  const [changeLogPage, setChangeLogPage] = useState(1);

  const fetchEmails = useCallback(
    (props?: FetchEmailsProps) => {
      setFilter(old => {
        try {
          fetchAutomaticEmails({
            ...old,
            ...props
          });
        } catch (error) {
          console.error('Erro ao buscar dados:', error);
        }

        return {
          ...old,
          ...props
        };
      });
    },
    [fetchAutomaticEmails]
  );

  function getChangeLog(props?: FetchEmailsProps) {
    const pageNumber = props?.pageNumber ?? 1;
    setChangeLogPage(pageNumber);
  }

  const handleSubmitSearch = () => {
    fetchEmails({ search, pageNumber: 1 });
    setCurrentPage(1);
  };

  const handleSort = (column: string) => {
    if (selectedOrderColumn === column) {
      fetchEmails({
        sort: column,
        order: filter.order === 'ASC' ? 'DESC' : 'ASC'
      });

      return;
    }

    fetchEmails({
      sort: column,
      order: 'ASC'
    });

    setSelectedOrderColumn(column);
  };

  const handleOpenModalChangeLog = (changeLog: ChangeLog[]) => {
    setCurrentChangeLog(changeLog);
    setIsOpenChangeLog(true);
  };

  const handleCloseModalChangeLog = () => {
    setIsOpenChangeLog(false);
  };

  const handleOpenModalActions = (email: AutomaticEmail) => {
    setCurrentEmail(email);
    setIsOpenActions(true);
  };

  const handleCloseModalActions = () => {
    setCurrentEmail(null);
    setIsOpenActions(false);
  };

  const handleToggleStatus = (isActive: boolean, id: string) => {
    toggleActiveAutomaticEmail({ isActive, id });
  };

  const tableColumnsEmail: ColumnTypes<AutomaticEmail>[] = [
    {
      key: 'subject',
      title: (
        <button key='order-by-subject' onClick={() => handleSort('subject')}>
          <span>Assunto</span>
          <SwapVertical />
        </button>
      ),
      width: 668
    },
    {
      key: 'status',
      title: (
        <button key='order-by-status' onClick={() => handleSort('isActive')}>
          <span>Status</span>
          <SwapVertical />
        </button>
      ),
      width: 150,
      render: (_, { isActive, id }) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%'
          }}
        >
          <Switch
            onChange={e => handleToggleStatus(e.target.checked, id)}
            id={id}
            checked={isActive}
          />
          <span>{isActive ? 'Ativo' : 'Inativo'}</span>
        </div>
      )
    },
    {
      key: 'change-log',
      title: (
        <button
          key='order-by-change-log'
          onClick={() => handleSort('changeLog')}
        >
          <span>Histórico de alterações</span>
          <SwapVertical style={{ margin: '0 auto' }} />
        </button>
      ),
      width: 220,
      render: (_, { changeLog }) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Visibility
            style={{ cursor: 'pointer' }}
            onClick={() => handleOpenModalChangeLog(changeLog)}
          />
        </div>
      )
    },
    {
      key: 'action',
      title: (
        <span
          style={{
            textAlign: 'center',
            width: '100%',
            display: 'block',
            paddingRight: 12
          }}
        >
          Ações
        </span>
      ),
      width: 150,
      render: (_, email) => (
        <div className='action-icons'>
          <div className='action-icon'>
            <Visibility onClick={() => handleOpenModalActions(email)} />
          </div>
        </div>
      )
    }
  ];

  const tableColumnsChangeLog: ColumnTypes<ChangeLog>[] = [
    {
      key: 'date',
      title: 'Data',
      render: (_, { date }) => (
        <span>{format(new Date(date), 'dd/MM/yyyy')}</span>
      )
    },
    {
      key: 'time',
      title: 'Hora',
      render: (_, { date }) => (
        <span>{format(addHours(new Date(date), 3), 'HH:mm:ss')}</span>
      )
    },
    {
      key: 'author',
      title: 'Autor(a)'
    }
  ];

  useEffect(() => {
    fetchEmails();
  }, [fetchEmails]);

  useEffect(() => {
    setEmailsList(automaticEmails.emailList ?? []);
    setEmailsCount(automaticEmails.emailListSize);

    if (currentEmail) {
      const email = automaticEmails.emailList?.find(
        email => email.id === currentEmail.id
      );

      if (email) {
        setCurrentEmail(email);
      }
    }
  }, [automaticEmails, currentEmail]);

  return (
    <>
      <div className='search-area'>
        <Input
          placeholder='Pesquisar email...'
          insideIcon={
            <Magnifier
              style={{ cursor: 'pointer' }}
              width={20}
              height={20}
              onClick={handleSubmitSearch}
            />
          }
          value={search}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSearch(e.currentTarget.value)
          }
          onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
              handleSubmitSearch();
            }
          }}
        />
      </div>

      <Table
        data={emailsList}
        columns={tableColumnsEmail}
        showFooter={true}
        dataCount={emailsCount}
        fetchMessages={fetchEmails}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        isLoading={isLoadingAutomaticEmails}
      />

      <Drawer
        open={isOpenChangeLog}
        onClose={handleCloseModalChangeLog}
        title='Histórico de alterações'
      >
        <Table
          data={currentChangeLog.slice(
            (changeLogPage - 1) * 5,
            (changeLogPage - 1) * 5 + 5
          )}
          columns={tableColumnsChangeLog}
          showFooter={true}
          dataCount={currentChangeLog.length}
          fetchMessages={getChangeLog}
          currentPage={changeLogPage}
          setCurrentPage={setChangeLogPage}
        />
      </Drawer>

      <EmailDetail
        currentEmail={currentEmail}
        handleCloseModalActions={handleCloseModalActions}
        handleToggleStatus={handleToggleStatus}
        isOpenActions={isOpenActions}
      />
    </>
  );
}

AutomaticEmails.displayName = 'AutomaticEmails';

export default connect(mapStateToProps, mapDispatchToProps)(AutomaticEmails);
