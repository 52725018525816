import React from 'react';
import { Modal } from '@ui/Modal';
import Button from '@ui/Button';
import { ButtonTypes } from '@ui/Button/button-types';

import './delete-modal.css';

type DeleteModalProps = {
  readonly confirmAction: () => void;
  readonly onHide: () => void;
  readonly show: boolean;
};

function DeleteModal({
  onHide,
  show,
  confirmAction
}: DeleteModalProps): JSX.Element {
  return (
    <Modal.Root
      animation={true}
      keyboard={true}
      onHide={onHide}
      show={show}
      className='delete-message-modal'
    >
      <Modal.Header onHide={onHide}>
        Tem certeza que deseja cancelar esse email?
      </Modal.Header>
      <Modal.Body>
        <p>Ao cancelar, o email não poderá ser reativado novamente.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={onHide}
          type='button'
          buttonType={ButtonTypes.Tertiary}
          style={{
            width: 'calc(50% - 8px)',
            height: 44
          }}
        >
          NÃO, DESISTIR
        </Button>

        <Button
          onClick={() => {
            onHide();
            confirmAction();
          }}
          type='button'
          buttonType={ButtonTypes.Primary}
          style={{
            width: 'calc(50% - 8px)',
            height: 44
          }}
        >
          SIM, CANCELAR
        </Button>
      </Modal.Footer>
    </Modal.Root>
  );
}

DeleteModal.displayName = 'DeleteModal';

export default DeleteModal;
