import React, { SyntheticEvent } from 'react';
import { Autocomplete, Checkbox, TextField } from '@mui/material';

import './multi-select.css';

type Option = {
  label: string;
  value: string;
};

type MultiSelectComboBoxProps = {
  options: Option[];
  selectedOptions?: Option[];
  error?: string;
  disabled?: boolean;
  onChange: (value: Option[]) => void;
};

const MultiSelectComboBox = ({
  options,
  selectedOptions = [],
  error,
  disabled,
  onChange
}: MultiSelectComboBoxProps): JSX.Element => {
  const [valueOptions, setValueOptions] = React.useState<Option[]>(
    options.filter(option =>
      selectedOptions?.map(item => item.value).includes(option.value)
    )
  );
  const allOption = { label: 'Todos', value: 'all' };

  const handleChange = (
    _: SyntheticEvent<Element, Event>,
    newValue: Option[]
  ) => {
    if (newValue.some(item => item.value === 'all')) {
      if (valueOptions.length === options.length) {
        setValueOptions([]);
        onChange([]);
      } else {
        setValueOptions(options);
        onChange(options);
      }
    } else {
      setValueOptions(newValue);
      onChange(newValue);
    }
  };

  return (
    <div className='multi-select'>
      <Autocomplete
        id='multiple-limit-tags'
        multiple
        limitTags={2}
        options={[allOption, ...options]}
        getOptionLabel={option => option.label}
        value={valueOptions}
        onChange={handleChange}
        disableCloseOnSelect
        renderOption={(props, option, { selected }) => {
          return (
            <li {...props}>
              <Checkbox
                style={{ marginRight: 8 }}
                checked={
                  selected ||
                  (option.value === 'all' &&
                    valueOptions.length === options.length)
                }
              />
              {option.label}
            </li>
          );
        }}
        renderInput={params => (
          <div className='form-group'>
            <div className='input-container'>
              <span className='control-label'>Departamento Regional</span>
            </div>
            <TextField
              {...params}
              placeholder='Digite algo...'
              size='small'
              sx={{
                '& .MuiOutlinedInput-root': {
                  minHeight: 44
                }
              }}
            />
          </div>
        )}
        disabled={disabled}
      />
      {error && <span className='error-message'>{error}</span>}
    </div>
  );
};

export default MultiSelectComboBox;
