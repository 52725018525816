/* eslint-disable  @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-var-requires, @typescript-eslint/no-unsafe-call,   @typescript-eslint/no-unsafe-member-access, @typescript-eslint/restrict-template-expressions,  @typescript-eslint/no-unsafe-return */
import React, { useState, Suspense, useMemo, useRef } from 'react';
import 'react-quill/dist/quill.snow.css';
import './rich-text-editor.css';
import ReactQuill from 'react-quill';

type RichTextEditorProps = {
  readonly defaultValue?: string;
  readonly error?: string | null;
  readonly disabled?: boolean;
  readonly onChange?: (value: string) => void;
  readonly onImageUpload?: (file: File, url: string) => void;
};

type StringMap = {
  [key: string]: any;
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'align',
  'list',
  'bullet',
  'link',
  'image'
];

const RichTextEditor = ({
  defaultValue,
  error,
  disabled,
  onChange,
  onImageUpload
}: RichTextEditorProps) => {
  const [value, setValue] = useState('');

  const reactQuillRef = useRef<ReactQuill | null>(null);

  React.useEffect(() => {
    setValue(defaultValue ?? '');
  }, [defaultValue]);

  const modules: StringMap = useMemo(() => {
    return {
      toolbar: {
        container: [
          [{ header: [1, 2, 3, false] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ align: '' }, { align: 'center' }, { align: 'right' }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['link', 'image'],
          ['clean']
        ],
        handlers: {
          image: () => {
            if (disabled) return;
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');
            input.click();

            input.onchange = () => {
              const Quill = require('react-quill');
              const file = input.files ? input.files[0] : null;

              if (file) {
                const Image = Quill.Quill.import('formats/image');
                const url = URL.createObjectURL(file);
                Image.sanitize = (url: string) => url;

                // const quill = this.quill;
                const quill = reactQuillRef?.current?.getEditor();

                if (!quill) {
                  return;
                }

                const range = quill.getSelection();
                quill?.insertEmbed(range?.index ?? 0, 'image', url);
                onImageUpload?.(file, url);
              }
            };
          }
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (content: string) => {
    setValue(content);
    onChange?.(content);
  };

  if (typeof document !== 'undefined') {
    const ReactQuill = require('react-quill');
    const SourceQuill = ReactQuill.Quill;

    const Link = SourceQuill.import('formats/link');
    Link.PROTOCOL_WHITELIST = [
      'http',
      'https',
      'mailto',
      'tel',
      'radar',
      'rdar',
      'smb',
      'sms'
    ];

    class CustomLinkSanitizer extends Link {
      static sanitize(url: string) {
        const sanitizedUrl = super.sanitize(url);
        if (!sanitizedUrl || sanitizedUrl === 'about:blank')
          return sanitizedUrl;

        const hasWhitelistedProtocol = this.PROTOCOL_WHITELIST.some(
          (protocol: string) => sanitizedUrl.startsWith(protocol)
        );

        if (hasWhitelistedProtocol) return sanitizedUrl;
        return `https://${sanitizedUrl}`;
      }
    }

    SourceQuill.register(CustomLinkSanitizer, true);

    return (
      <Suspense fallback={<div>Loading Editor...</div>}>
        <div className='rich-text-container'>
          <ReactQuill
            ref={reactQuillRef}
            theme='snow'
            value={value}
            onChange={handleChange}
            modules={modules}
            formats={formats}
            placeholder='Digite algo aqui...'
            style={{ height: '100%' }}
            readOnly={disabled}
          />
          {error && <span className='error-message'>{error}</span>}
        </div>
      </Suspense>
    );
  } else {
    return <div />;
  }
};

export default RichTextEditor;
