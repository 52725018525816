import React, {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useState
} from 'react';
import { connect } from 'react-redux';
import Drawer from '@ui/Drawer';
import Button from '@ui/Button';
import { ButtonTypes } from '@ui/Button/button-types';
import { ManualEmailsProps } from '@redux/prop-types';
import {
  isLoadingManualEmailsSelector,
  manualEmailSelector,
  showEmailDrawerSelector
} from '@redux/selectors';
import { createSelector } from 'reselect';
import {
  createManualEmail,
  editManualEmail,
  fetchManualEmails,
  openEmailDrawer,
  closeEmailDrawer
} from '@redux/actions';
import { format, isValid } from 'date-fns';
import { FilterOptionsProps } from '@utils/api';
import Input from '@ui/Input';
import Magnifier from '@assets/icons/magnifier';
import { useForm } from 'react-hook-form';
import { ColumnTypes } from '../../../Table/types';
import Table from '../../../Table';
import SwapVertical from '../../../../assets/icons/swap-vertical';
import Visibility from '../../../../assets/icons/visibility';
import Plus2 from '../../../../assets/icons/plus2';
import { FetchEmailsProps } from '../types';
import ManualEmailForm from './components/manual-email-form';
import { EmailRecipients, ManualEmail } from './types';
import DrawerRecipients from './components/drawer-recipients';
import './manual-emails.css';

interface ManualEmailsPageProps {
  readonly manualEmails: ManualEmailsProps;
  readonly showEmailDrawer: boolean;
  readonly isLoadingManualEmails: boolean;
  readonly fetchManualEmails: (props: FilterOptionsProps) => void;
  readonly createManualEmail: (data: ManualEmail) => void;
  readonly editManualEmail: (data: ManualEmail) => void;
  readonly openEmailDrawer: () => void;
  readonly closeEmailDrawer: () => void;
}

const initialFilterOptions: FilterOptionsProps = {
  pageNumber: 1,
  pageSize: 5,
  search: '',
  sort: 'sendDate',
  order: 'DESC'
};

const mapStateToProps = createSelector(
  manualEmailSelector,
  showEmailDrawerSelector,
  isLoadingManualEmailsSelector,
  (
    manualEmails: ManualEmailsProps,
    showEmailDrawer: boolean,
    isLoadingManualEmails: boolean
  ) => ({
    manualEmails,
    showEmailDrawer,
    isLoadingManualEmails
  })
);

const mapDispatchtoProps = {
  fetchManualEmails,
  createManualEmail,
  editManualEmail,
  openEmailDrawer,
  closeEmailDrawer
};

const renderStatus:
  | ((column: ColumnTypes<ManualEmail>, item: ManualEmail) => void)
  | undefined = (_, { status }: ManualEmail) => {
  return <span style={{ textTransform: 'capitalize' }}>{status}</span>;
};

function ManualEmails({
  manualEmails,
  showEmailDrawer,
  isLoadingManualEmails,
  fetchManualEmails,
  createManualEmail,
  editManualEmail,
  openEmailDrawer,
  closeEmailDrawer
}: ManualEmailsPageProps): JSX.Element {
  const [isOpenRecipients, setIsOpenRecipients] = useState(false);
  const [currentRecipients, setCurrentRecipients] =
    useState<EmailRecipients | null>(null);
  const [currentEmail, setCurrentEmail] = useState<ManualEmail | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] =
    useState<FilterOptionsProps>(initialFilterOptions);

  const fetchEmails = useCallback(
    (props?: FetchEmailsProps) => {
      setFilter(old => {
        try {
          fetchManualEmails({
            ...old,
            ...props
          });
        } catch (error) {
          console.error('Erro ao buscar dados:', error);
        }

        return {
          ...old,
          ...props
        };
      });
    },
    [fetchManualEmails]
  );

  const handleSort = (column: string) => {
    if (filter.sort === column) {
      fetchEmails({
        sort: column,
        order: filter.order === 'ASC' ? 'DESC' : 'ASC'
      });
      return;
    }

    fetchEmails({
      sort: column,
      order: 'ASC'
    });

    setFilter({ ...filter, sort: column });
  };

  const handleSubmitSearch = () => {
    void fetchEmails({ search: filter.search, pageNumber: 1 });
    setCurrentPage(1);
  };

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, search: event.target.value });
  };

  const handleOpenModalRecipients = (recipients: EmailRecipients) => {
    setCurrentRecipients(recipients);
    setIsOpenRecipients(true);
  };

  const handleCloseModalRecipients = () => {
    setIsOpenRecipients(false);
  };

  const handleOpenModalActions = (email: ManualEmail | null) => {
    setCurrentEmail(email);
    openEmailDrawer();
  };

  const handleCloseModalActions = () => {
    setCurrentEmail(null);
    closeEmailDrawer();
  };

  const tableColumnsEmail: ColumnTypes<ManualEmail>[] = [
    {
      key: 'subject',
      title: (
        <button key='order-by-subject' onClick={() => handleSort('subject')}>
          <span>Assunto</span>
          <SwapVertical />
        </button>
      ),
      width: 518
    },
    {
      key: 'status',
      title: (
        <button key='order-by-status' onClick={() => handleSort('status')}>
          <span>Status</span>
          <SwapVertical />
        </button>
      ),
      width: 185,
      render: renderStatus
    },
    {
      key: 'send-date',
      title: (
        <button key='order-by-send-date' onClick={() => handleSort('sendDate')}>
          <span>Data de envio</span>
          <SwapVertical />
        </button>
      ),
      width: 150,
      render: (_, { sendDate }) =>
        sendDate && isValid(new Date(sendDate))
          ? format(new Date(sendDate), 'dd/MM/yyyy')
          : ''
    },
    {
      key: 'recipients',
      title: (
        <button key='order-by-recipients'>
          <span>Destinatário(s)</span>
        </button>
      ),
      width: 120,
      render: (_, { recipients }) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Visibility onClick={() => handleOpenModalRecipients(recipients)} />
        </div>
      )
    },
    {
      key: 'action',
      title: (
        <span
          style={{
            textAlign: 'center',
            width: '100%',
            display: 'block',
            paddingRight: 12
          }}
        >
          Ações
        </span>
      ),
      width: 150,
      render: (_, email) => (
        <div className='action-icons'>
          <div className='action-icon'>
            <Visibility onClick={() => handleOpenModalActions(email)} />
          </div>
        </div>
      )
    }
  ];

  useEffect(() => {
    fetchEmails();
  }, [fetchEmails]);

  return (
    <>
      <div className='header-actions' style={{ gap: 16 }}>
        <div className='search-area'>
          <Input
            placeholder='Pesquisar email...'
            insideIcon={
              <Magnifier
                style={{ cursor: 'pointer' }}
                width={20}
                height={20}
                onClick={handleSubmitSearch}
              />
            }
            value={filter.search}
            onChange={handleChangeSearch}
            onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
              if (event.key === 'Enter') {
                handleSubmitSearch();
              }
            }}
          />
        </div>

        <Button
          buttonType={ButtonTypes.Primary}
          onClick={() => handleOpenModalActions(null)}
          style={{ height: 40 }}
        >
          <Plus2 />
          <span>NOVO E-MAIL</span>
        </Button>
      </div>

      {/* Emails */}
      <Table
        data={manualEmails?.emailList || []}
        columns={tableColumnsEmail}
        showFooter={true}
        dataCount={manualEmails?.emailListSize ?? null}
        fetchMessages={fetchEmails}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        isLoading={isLoadingManualEmails}
      />

      {/* Recipients */}
      <DrawerRecipients
        isOpenRecipients={isOpenRecipients}
        currentRecipients={currentRecipients}
        handleCloseModalRecipients={handleCloseModalRecipients}
      />

      {/* Form */}
      <Drawer
        open={showEmailDrawer}
        onClose={handleCloseModalActions}
        title={currentEmail ? 'Visualizar e-mail manual' : 'Novo e-mail manual'}
      >
        <ManualEmailForm
          currentEmail={currentEmail}
          useForm={useForm}
          createManualEmail={createManualEmail}
          editManualEmail={editManualEmail}
          isLoading={manualEmails.loading}
        />
      </Drawer>
    </>
  );
}

ManualEmails.displayName = 'ManualEmails';

export default connect(mapStateToProps, mapDispatchtoProps)(ManualEmails);
