import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import Drawer from '@ui/Drawer';
import Input from '@ui/Input';
import RichEditor from '@ui/RichEditor';
import Switch from '@ui/Switch';
import Button from '@ui/Button';
import { ButtonTypes } from '@ui/Button/button-types';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  AutomaticEmail,
  AutomaticEmailsProps,
  EditAutomaticEmailProps,
  FormInputs
} from '@redux/prop-types';
import { automaticEmailsSelector } from '@redux/selectors';
import { editAutomaticEmail } from '@redux/actions';

type EmailDetailProps = {
  isOpenActions: boolean;
  handleCloseModalActions: () => void;
  currentEmail: AutomaticEmail | null;
  handleToggleStatus: (isActive: boolean, id: string) => void;
  editAutomaticEmail: (props: EditAutomaticEmailProps) => void;
  automaticEmails: AutomaticEmailsProps;
};

const mapDispatchToProps = { editAutomaticEmail };

const mapStateToProps = createSelector(
  automaticEmailsSelector,
  (automaticEmails: AutomaticEmailsProps) => ({
    automaticEmails
  })
);

function EmailDetail({
  isOpenActions,
  handleCloseModalActions,
  currentEmail,
  handleToggleStatus,
  editAutomaticEmail
}: EmailDetailProps): JSX.Element {
  const {
    register,
    reset,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm<FormInputs>({
    defaultValues: {
      recipients: currentEmail?.recipients,
      subject: currentEmail?.subject,
      emailContent: currentEmail?.emailContent
    }
  });

  const handleSave: SubmitHandler<FormInputs> = data => {
    // manual validation
    if (data.emailContent.trim() === '<p><br></p>') {
      setError('emailContent', {
        type: 'manual',
        message: 'O conteúdo do e-mail não pode estar vazio'
      });
      return;
    }
    delete (data as Partial<FormInputs>).recipients;
    editAutomaticEmail({ id: currentEmail?.id ?? '', data });
    handleCloseModalActions();
  };

  useEffect(() => {
    reset({
      recipients: currentEmail?.recipients || '',
      subject: currentEmail?.subject || '',
      emailContent: currentEmail?.emailContent || ''
    });
  }, [currentEmail, reset]);

  return (
    <Drawer
      open={isOpenActions}
      onClose={handleCloseModalActions}
      title='Visualizar e-mail'
    >
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(handleSave)}>
        <Input
          label='Destinatário'
          placeholder='Destinatário'
          disabled
          error={errors.recipients?.message}
          {...register('recipients', {
            required: {
              message: 'O campo é obrigatório',
              value: true
            },
            minLength: {
              message: 'O campo deve ter no mínimo 3 caracteres',
              value: 3
            }
          })}
        />

        <Input
          label='Assunto'
          placeholder='Assunto'
          error={errors.subject?.message}
          {...register('subject', {
            required: {
              message: 'O campo é obrigatório',
              value: true
            },
            minLength: {
              message: 'O campo deve ter no mínimo 3 caracteres',
              value: 3
            }
          })}
        />

        <RichEditor
          error={errors.emailContent?.message}
          value={getValues('emailContent')}
          onChange={value => setValue('emailContent', value)}
        />

        <div className='drawer-footer'>
          <div>
            <Switch
              id={currentEmail?.id ?? ''}
              checked={currentEmail?.isActive ?? false}
              onChange={e =>
                currentEmail?.id &&
                handleToggleStatus(e.target.checked, currentEmail.id)
              }
            />
            <span>{currentEmail?.isActive ? 'Ativo' : 'Inativo'}</span>
          </div>
          <Button
            type='submit'
            buttonType={ButtonTypes.Primary}
            style={{ height: 34 }}
          >
            Salvar
          </Button>
        </div>
      </form>
    </Drawer>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailDetail);
