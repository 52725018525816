import React from 'react';

import './switch.css';

type SwitchProps = { id: string } & React.InputHTMLAttributes<HTMLInputElement>;

function Switch({ id, ...props }: SwitchProps): JSX.Element {
  return (
    <div className='toggle-switch'>
      <input className='toggle-input' id={id} type='checkbox' {...props} />
      <label className='toggle-label' htmlFor={id}>
        {''}
      </label>
    </div>
  );
}

Switch.displayName = 'Switch';

export default Switch;
