import React, { ChangeEvent, useEffect, useState } from 'react';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { format, isBefore, isEqual, setHours, startOfMinute } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import './calendar.css';
import { Modal } from '@ui/Modal';
import Button from '@ui/Button';
import { ButtonTypes } from '@ui/Button/button-types';

type CalendarProps = {
  readonly show: boolean;
  readonly handleClose: () => void;
  readonly value: Date | null;
  readonly onChoose: (date: Date) => void;
  removeSendDate: () => void;
};

function Calendar({
  show,
  handleClose,
  value: valueDate,
  onChoose,
  removeSendDate
}: CalendarProps): JSX.Element {
  const [value, setValue] = useState<Date | null>(valueDate);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    setValue(valueDate || new Date());
    setErrorMessage('');
  }, [valueDate]);

  const handleDateChange = (newValue: Date) => {
    setErrorMessage('');
    setValue(newValue);
  };

  const hadleTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('');
    const hours = Number(event.currentTarget.value.split(':')[0]);
    const minutes = Number(event.currentTarget.value.split(':')[1]);
    if (value === null || isNaN(hours) || isNaN(minutes)) {
      setValue(null);
      return;
    }
    const newDate: Date = setHours(new Date(value), hours);
    newDate.setMinutes(minutes);
    setValue(newDate);
  };

  const validateDate = (date: string) => {
    const now = startOfMinute(new Date());
    const isBeforeOrEqual =
      isBefore(startOfMinute(new Date(date)), now) ||
      isEqual(startOfMinute(new Date(date)), now);
    return isBeforeOrEqual;
  };

  const handleChoose = () => {
    const hasError = validateDate(value);
    if (hasError) {
      setErrorMessage('Data e hora devem ser posteriores ao momento atual');
      return;
    }
    onChoose(value);
    handleClose();
  };

  return (
    <Modal.Root show={show}>
      <Modal.Header closeButton onHide={handleClose}>
        Programar envio
      </Modal.Header>

      <Modal.Body>
        <div className='calendar-container'>
          <div className='calendar-item'>
            <DateCalendar
              value={value && new Date(value)}
              onChange={handleDateChange}
              minDate={new Date()}
            />
          </div>
          <div className='display-selection'>
            <span className='display-date'>
              {value &&
                format(new Date(value), 'dd/MM/yyyy', {
                  locale: ptBR
                })}
            </span>

            <input
              className='display-time'
              type='time'
              value={
                value &&
                format(new Date(value), 'HH:mm', {
                  locale: ptBR
                })
              }
              onChange={hadleTimeChange}
            />
            {errorMessage && (
              <span className='error-message'>{errorMessage}</span>
            )}
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className='footer-align-left'>
          <Button
            type='button'
            buttonType={ButtonTypes.Tertiary}
            onClick={removeSendDate}
          >
            Remover programação
          </Button>
        </div>
        <div className='footer-align-right'>
          <Button
            type='button'
            buttonType={ButtonTypes.Tertiary}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            type='button'
            buttonType={ButtonTypes.Primary}
            onClick={handleChoose}
          >
            Programar envio
          </Button>
        </div>
      </Modal.Footer>
    </Modal.Root>
  );
}

export default Calendar;
