import React, { ChangeEvent, useEffect, useState } from 'react';
import { addDays, format, getDate, getDay, startOfMonth } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Modal } from '@ui/Modal';
import Button from '@ui/Button';
import { ButtonTypes } from '@ui/Button/button-types';
import Input from '@ui/Input';
import Select from '@ui/Select';
import { Radio, RadioGroup } from '@mui/material';
import Calendar from '@ui/Calendar';
import Calendar2 from '../../../../../../assets/icons/calendar2';
import {
  CustomRecurrence,
  CustomRecurringOption,
  FinishAtOption
} from '../../types';

import './custom-recurring-modal.css';

type CustomRecurringModalProps = {
  readonly show: boolean;
  readonly startAt: Date;
  readonly customRecurrence?: CustomRecurrence;
  readonly handleClose: () => void;
  readonly onChoose: (data: CustomRecurrence) => void;
};

type Option = {
  value: string;
  option: string;
};

type FormErrorProps = Record<
  string,
  { error: boolean; message: string; type: string }
>;

const recurringOptions: Option[] = [
  { value: 'day', option: 'Dia' },
  {
    value: 'week',
    option: 'Semana'
  },
  { value: 'month', option: 'Mês' }
];

const weekDays = [
  'Domingo',
  'Segunda',
  'Terça',
  'Quarta',
  'Quinta',
  'Sexta',
  'Sábado'
];

const defaultFormDataValues: CustomRecurrence = {
  recurringInterval: 1,
  recurringType: 'day',
  dayOfWeeks: undefined,
  finishAt: {
    type: 'never',
    onDate: undefined,
    onCount: undefined
  }
};

const defaultFormErrors: FormErrorProps = {
  dayOfWeeks: {
    error: false,
    message: 'Selecione pelo menos um dia',
    type: 'required'
  },
  onDate: {
    error: false,
    message: 'Selecione uma data válida',
    type: 'required'
  },
  onCount: {
    error: false,
    message: 'Informe um número',
    type: 'required'
  }
};

const getRecurringOptions = (recurringDate: Date): Option[] => {
  function getWeekdayOccurrenceString(_date: Date): string {
    const date = new Date(_date);
    const dayOfWeek = getDay(date);
    const firstDayOfMonth = startOfMonth(date);
    let day = firstDayOfMonth;
    let occurrence = 0;

    const ordinalNumbers = [
      'primeiro(a)',
      'segundo(a)',
      'terceiro(a)',
      'quarto(a)',
      'quinto(a)'
    ];

    while (day.getMonth() === date.getMonth()) {
      if (getDay(day) === dayOfWeek) {
        occurrence += 1;
        if (day.getDate() === date.getDate()) {
          const weekdayName = format(date, 'eeee', { locale: ptBR });
          return `no(a) ${ordinalNumbers[occurrence - 1]} ${weekdayName}`;
        }
      }
      day = addDays(day, 1);
    }

    return 'Data inválida';
  }

  const getOptonsLabel = (value: string): string => {
    switch (value) {
      case 'monthly-at-date':
        return `Mensal no dia ${getDate(new Date(recurringDate))}`;
      case 'monthly-on-weekday':
        return `Mensal ${getWeekdayOccurrenceString(recurringDate)}`;
      default:
        return '';
    }
  };

  const recurringOptions: Option[] = [
    {
      value: 'monthly-at-date',
      option: getOptonsLabel('monthly-at-date')
    },
    {
      value: 'monthly-on-weekday',
      option: getOptonsLabel('monthly-on-weekday')
    }
  ];

  return recurringOptions;
};

function CustomRecurringModal({
  show,
  startAt = new Date(),
  customRecurrence,
  handleClose,
  onChoose
}: CustomRecurringModalProps): JSX.Element {
  const [calendarModalIsOpen, setCalendarModalIsOpen] = useState(false);
  const [recurringFormData, setRecurringFormData] = useState<CustomRecurrence>(
    defaultFormDataValues
  );
  const [recurringFormErrors, setRecurringFormErrors] =
    useState<FormErrorProps>(defaultFormErrors);

  useEffect(() => {
    if (customRecurrence) {
      setRecurringFormData(customRecurrence);
    }
  }, [customRecurrence]);

  const handleOpenCalendarModal = () => {
    if (recurringFormData?.finishAt.type === 'on-date') {
      setCalendarModalIsOpen(true);
    }
  };

  const handleCloseCalendarModal = () => {
    setCalendarModalIsOpen(false);
  };

  const handleChangeRecurringInterval = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setRecurringFormData({
      ...recurringFormData,
      recurringInterval: parseInt(event.target.value)
    });
  };

  const handleChangeRecurringType = (event: ChangeEvent<HTMLSelectElement>) => {
    setRecurringFormData({
      ...recurringFormData,
      dayOfWeeks: undefined,
      monthRecurrence: undefined,
      recurringType: event.target.value as CustomRecurringOption
    });
  };

  const handleChangeRecurringMonthType = (
    event: ChangeEvent<HTMLSelectElement>
  ) => {
    setRecurringFormData({
      ...recurringFormData,
      monthRecurrence: {
        type: event.target.value as 'monthly-at-date' | 'monthly-on-weekday'
      }
    });
  };

  const handleClickRecurringWeekDay = (index: number) => {
    const dayOfWeeks = recurringFormData.dayOfWeeks ?? [];

    const newDayOfWeeks = dayOfWeeks.includes(index)
      ? dayOfWeeks.filter((i: number) => i !== index)
      : [...dayOfWeeks, index];

    if (newDayOfWeeks.length === 0) {
      setRecurringFormErrors({
        ...recurringFormErrors,
        dayOfWeeks: {
          error: true,
          message: 'Selecione pelo menos um dia',
          type: 'required'
        }
      });
    } else {
      setRecurringFormErrors({
        ...recurringFormErrors,
        dayOfWeeks: {
          error: false,
          message: 'Selecione pelo menos um dia',
          type: 'required'
        }
      });
    }

    setRecurringFormData({
      ...recurringFormData,
      dayOfWeeks: newDayOfWeeks
    });
  };

  const handleChangeFinishAtOption = (event: ChangeEvent<HTMLInputElement>) => {
    setRecurringFormErrors(defaultFormErrors);

    setRecurringFormData({
      ...recurringFormData,
      finishAt: {
        ...defaultFormDataValues.finishAt,
        type: event.target.value as FinishAtOption
      }
    });
  };

  const handleChooseRecurringDate = (date: Date) => {
    setRecurringFormData({
      ...recurringFormData,
      finishAt: {
        ...recurringFormData.finishAt,
        onDate: date
      }
    });
  };

  const handleChangeFinishAtCount = (event: ChangeEvent<HTMLInputElement>) => {
    setRecurringFormData({
      ...recurringFormData,
      finishAt: {
        ...recurringFormData.finishAt,
        onCount: parseInt(event.target.value)
      }
    });
  };

  const verifyFormErrors = () => {
    let formHasError = false;
    let tempFormErrors = recurringFormErrors;
    const dayOfWeeks = recurringFormData.dayOfWeeks ?? [];

    if (recurringFormData.recurringType === 'week' && dayOfWeeks.length === 0) {
      tempFormErrors = {
        ...recurringFormErrors,
        dayOfWeeks: {
          error: true,
          message: 'Selecione pelo menos um dia da semana',
          type: 'required'
        }
      };

      formHasError = true;
    }

    if (
      recurringFormData.finishAt.type === 'on-date' &&
      !recurringFormData.finishAt.onDate
    ) {
      tempFormErrors = {
        ...recurringFormErrors,
        onDate: {
          error: true,
          message: 'Selecione uma data',
          type: 'required'
        }
      };

      formHasError = true;
    }

    if (
      recurringFormData.finishAt.type === 'on-count' &&
      !recurringFormData.finishAt.onCount
    ) {
      tempFormErrors = {
        ...recurringFormErrors,
        onCount: {
          error: true,
          message: 'Selecione um número',
          type: 'required'
        }
      };

      formHasError = true;
    }

    setRecurringFormErrors(tempFormErrors);
    return formHasError;
  };

  const handleChoose = () => {
    const formHasError = verifyFormErrors();

    if (formHasError) {
      return;
    }

    onChoose(recurringFormData);
    handleCloseCustomRecurringModal();
  };

  const handleCloseCustomRecurringModal = () => {
    setRecurringFormData(customRecurrence || defaultFormDataValues);
    setRecurringFormErrors(defaultFormErrors);
    handleClose();
  };

  return (
    <Modal.Root show={show} className='custom-recurring-modal'>
      <Modal.Header closeButton onHide={handleClose}>
        Recorrência personalizada
      </Modal.Header>

      <Modal.Body>
        <div className='custom-recurring-modal-body'>
          <div className='choose-recurring'>
            <span>Recorrência a cada</span>

            <Input
              type='number'
              value={recurringFormData.recurringInterval}
              onChange={handleChangeRecurringInterval}
            />

            <Select
              options={recurringOptions}
              defaultValue={recurringOptions[0].value}
              value={recurringFormData.recurringType}
              onChange={handleChangeRecurringType}
            />
          </div>

          {recurringFormData.recurringType === 'week' && (
            <div className='specify-recurring'>
              <span>Recorrência</span>
              <div className='recurring-wrapper'>
                {weekDays.map((day, index) => (
                  <button
                    key={`${index}-${day}`}
                    className={`recurring-day ${
                      recurringFormData.dayOfWeeks?.includes(index)
                        ? 'selected'
                        : ''
                    }`}
                    onClick={() => handleClickRecurringWeekDay(index)}
                  >
                    {day.toUpperCase().charAt(0)}
                  </button>
                ))}
              </div>
              {recurringFormErrors.dayOfWeeks.error && (
                <span className='error-message'>
                  {recurringFormErrors.dayOfWeeks.message}
                </span>
              )}
            </div>
          )}
          {recurringFormData.recurringType === 'month' && (
            <div className='specify-recurring'>
              <Select
                options={getRecurringOptions(startAt)}
                value={recurringFormData.monthRecurrence?.type}
                onChange={handleChangeRecurringMonthType}
              />
            </div>
          )}

          <div className='choose-end'>
            <span>Termina em</span>

            <RadioGroup
              value={recurringFormData.finishAt.type}
              onChange={handleChangeFinishAtOption}
            >
              <div className='radio-label-wrapper'>
                <Radio value='never' />
                <label htmlFor='never'>Nunca</label>
              </div>

              <div>
                <div className='radio-field-wrapper'>
                  <div className='radio-label-wrapper'>
                    <Radio value='on-date' />
                    <label htmlFor='on-date'>Em</label>
                  </div>

                  <Input
                    id='email-end-at'
                    type='text'
                    style={{ flex: 1 }}
                    insideIcon={<Calendar2 />}
                    onClick={handleOpenCalendarModal}
                    value={
                      recurringFormData?.finishAt?.onDate
                        ? format(
                            new Date(recurringFormData.finishAt.onDate),
                            "dd 'de' MMM., 'de' yyyy",
                            {
                              locale: ptBR
                            }
                          )
                        : ''
                    }
                    readOnly
                  />

                  <Calendar
                    show={calendarModalIsOpen}
                    handleClose={handleCloseCalendarModal}
                    value={
                      recurringFormData?.finishAt?.onDate
                        ? new Date(recurringFormData?.finishAt?.onDate)
                        : new Date()
                    }
                    onChoose={handleChooseRecurringDate}
                  />
                </div>

                {recurringFormErrors.onDate.error && (
                  <span className='error-message'>
                    {recurringFormErrors.onDate.message}
                  </span>
                )}
              </div>

              <div>
                <div className='radio-field-wrapper'>
                  <div className='radio-label-wrapper'>
                    <Radio value='on-count' />
                    <label htmlFor='on-count'>Após</label>
                  </div>

                  <Input
                    type='number'
                    disabled={recurringFormData.finishAt.type !== 'on-count'}
                    style={{ flex: 1 }}
                    value={recurringFormData.finishAt.onCount ?? ''}
                    onChange={handleChangeFinishAtCount}
                  />
                  <p style={{ marginLeft: 26 }}>Envios</p>
                </div>

                {recurringFormErrors.onCount.error && (
                  <span className='error-message'>
                    {recurringFormErrors.onCount.message}
                  </span>
                )}
              </div>
            </RadioGroup>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className='footer-align-right'>
          <Button
            type='button'
            buttonType={ButtonTypes.Tertiary}
            onClick={handleCloseCustomRecurringModal}
          >
            Cancelar
          </Button>
          <Button
            type='button'
            buttonType={ButtonTypes.Primary}
            onClick={handleChoose}
          >
            Salvar
          </Button>
        </div>
      </Modal.Footer>
    </Modal.Root>
  );
}

export default CustomRecurringModal;
