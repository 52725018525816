/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  styled
} from '@mui/material';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import ErrorMessage from '@ui/ErrorMessage';
import useDebounce from '../../../utils/hooks/use-debouce';

const Container = styled('div')`
  margin-bottom: 15px;
`;

const Label = styled('label')`
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: left;
  color: #707070;
  margin: 0;
`;

interface AsyncSelectProps<T> {
  handleSearch: (search: string) => Promise<T[]>;
  getOptionLabel: (option: T) => string;
  getOptionKey: (option: T) => string;
  renderOption?: (props: any, option: any) => JSX.Element;
  renderInput: (params: AutocompleteRenderInputParams) => React.ReactNode;
  defaultValue?: T[];
  label?: string;
  error?: string;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent, value: T[]) => void;
}

const AsyncSelect = forwardRef<HTMLSelectElement, AsyncSelectProps<any>>(
  function AsyncSelect(
    {
      handleSearch,
      getOptionLabel,
      getOptionKey,
      renderOption,
      renderInput,
      defaultValue = [],
      label,
      error,
      disabled,
      ...rest
    },
    ref
  ): JSX.Element {
    const [options, setOptions] = useState<unknown[]>([]);
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState<unknown[]>(defaultValue);

    const fetchOptions = useCallback(
      async (search: string) => {
        setLoading(true);
        try {
          const data: any[] = await handleSearch(search);

          if (Array.isArray(data)) {
            const filteredData = data.filter(
              d => !value.find(v => getOptionKey(v) === getOptionKey(d))
            );
            setOptions(filteredData);
          }
        } catch (error) {
          console.error('Erro ao buscar dados:', error);
          setOptions([]);
        } finally {
          setLoading(false);
        }
      },
      [handleSearch, getOptionKey, value]
    );

    const debouncedFetchOptions = useDebounce(fetchOptions, 500);

    useEffect(() => {
      if (inputValue) {
        debouncedFetchOptions(inputValue);
      } else {
        setOptions([]);
      }
    }, [inputValue, debouncedFetchOptions]);

    return (
      <Container>
        <Label>{label}</Label>
        <Autocomplete
          multiple
          defaultValue={defaultValue}
          filterOptions={x => (Array.isArray(x) ? x : [])}
          getOptionLabel={getOptionLabel}
          getOptionKey={getOptionKey}
          options={options}
          autoComplete
          includeInputInList
          filterSelectedOptions
          loading={loading}
          value={value}
          noOptionsText={loading ? 'Carregando...' : 'Nenhuma opção encontrada'}
          onInputChange={(e, newInputValue) => {
            setInputValue(newInputValue);
          }}
          ref={ref}
          renderOption={renderOption}
          renderInput={renderInput}
          {...rest}
          onChange={(_e, newValue) => {
            if (rest?.onChange) {
              rest.onChange(_e as React.ChangeEvent<Element>, newValue);
            }
            setValue(newValue);
            if (Array.isArray(newValue)) {
              setOptions(prevOptions => [...newValue, ...prevOptions]);
            }
          }}
          disabled={disabled}
        />
        <ErrorMessage error={error} />
      </Container>
    );
  }
);

export default AsyncSelect;
